import { thisIndex } from "./main";
export const debugLogger = (...messages: any[]): void => {
  if (
    (window as any).GLOBAL_SETTINGS &&
    (window as any).GLOBAL_SETTINGS.webrtc_streamer_debug
  ) {
    console.groupCollapsed(
      "---  " + performance.now() / 1000 + "s since document created  ---\n",
      messages
    );
    console.trace(); // hidden in collapsed group
    console.groupEnd();
  }

  if (
    (window as any).GLOBAL_SETTINGS.webrtc_streamer_vue &&
    (window as any).GLOBAL_SETTINGS.webrtc_streamer_vue[thisIndex]
  ) {
    (window as any).GLOBAL_SETTINGS.webrtc_streamer_vue[thisIndex].logs +=
      messages
        .map((message) =>
          typeof message == "object" ? JSON.stringify(message) : message
        )
        .concat("\n");
  }
};
