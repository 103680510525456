

import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import {
  WebRTCConfig,
  WebsocketCommunication,
  WebsocketCommunicationResponse,
  WrtcCall,
  RTCSessionDescriptionInit,
  WrtcHangup,
  WsResponses,
  InputArgs,
  RTCIceServer,
  PromiseCallbackFunctions,
  WebrtcOptions,
  TurnServers,
  isJqueryError,
  Camera,
  ActionsFromServer,
  JavaServerRequestResponseActions,
} from "@/types";
import Sockette from "sockette";
import { debugLogger } from "../debug";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import SimplePeer from "simple-peer";

@Component({})
export default class TheWebRTCVideoSetup extends Vue {
  // ---------- Props ----------

  @Prop() promiseCallbackFunctions!: PromiseCallbackFunctions;

  /** The options passed to us when the component is embedded. */
  @Prop() webrtcOptions!: WebrtcOptions;

  /** Do special things if testing locally. */
  @Prop() testingLocally!: boolean;

  /** The video options for videojs. */
  @Prop() videoOptions!: VideoJsPlayerOptions;

  /** The video options for videojs. */
  @Prop() turnServers!: TurnServers;

  /** Whether or not we are in auto live. */
  @Prop() isAutoLive!: boolean;

  // ------- Local Vars --------

  /** The config (if any) returned by the java server. */
  webRTCConfig!: WebRTCConfig;

  /** The saved websocket connection */
  websocket!: Sockette;

  /** The peer that is used to set up the Webrtc connection. */
  peer!: SimplePeer.Instance;

  /** The videojs player that is playing the stream */
  videojs!: VideoJsPlayer;

  /** The ice servers that the simple-peer will use (includes turn). */
  iceServers: RTCIceServer[] = [
    {
      urls: ["stun:turn.test.camio.com"],
    },
  ];

  /** Know if we initiated the hangup process or not (so we don't infinitely try to hang up) */
  hasSentHangup = false;

  /** The next step of the communication back and forth for webrtc. */
  expectingResponse: WsResponses = "webrtc-call";

  /** The id of the currently running timeout so we can cancel if need be. */
  webrtcWebsocketTimeoutIds: ReturnType<typeof setTimeout>[] = [];

  /** How many times we have renegotiated (used to prevent infinite negotiation loops) */
  timesRenegotiated = 0;

  /** The urls that are used for the websocket communication (trigger = send) */
  TRIGGER_URL = "";
  WEBSOCKET_URL = "";

  /** The timeout for retries (just so we don't spam before we get responses or have time to handle anything) */
  TIMEOUT_LENGTH_MS = 500;

  /** The maximum amount of renegotiation attempts before just accepting the result and trying to connect. */
  MAX_RENEGOTIATION = 5;

  /** This (hopefully) is in sync with the object defined in the server for the appVersion for webrtc */
  AUTO_START_LIVE_CAPABILITIES = [
    "name_changeability",
    "video",
    "per_camera_settings",
    "new_authorizations",
    "encoding_settings",
    "rtsp_stream_reading",
    "auto_start_live",
  ];
  HLS_CAPABILITIES = [
    "name_changeability",
    "video",
    "per_camera_settings",
    "new_authorizations",
    "encoding_settings",
    "rtsp_stream_reading",
    "rtmp",
    (window as any).liveWidget?.autoLiveAttempted, // This is a big hacky, but this is what tells us to not autostart again in the ui
  ];

  /** Wait 30 seconds before quitting all timeouts and aborting. */
  TOTAL_WEBRTC_PROCESS_TIMEOUT_MS = 60000;
  totalWebrtcProcessTimeoutIds: ReturnType<typeof setTimeout>[] = [];

  /** With instant live, we can somehow miss the first message, this starts the process over if we do miss it. */
  AWAITING_RESPONSE_TIMEOUT_MS = 30 * 1000;
  boxTimeoutMs = 2000;
  firstMessageReceivedTimeoutIds: ReturnType<typeof setTimeout>[] = [];

  // --------- Watchers --------
  // ------- Lifecycle ---------
  beforeDestroy(): void {
    debugLogger("Destroying Webrtc child");
    this.handleHangup(true);
  }

  mounted(): void {
    if (this.turnServers && this.turnServers.turn_address) {
      this.iceServers.push(
        {
          urls: [this.turnServers.turn_address],
          username: this.turnServers.turn_username,
          credential: this.turnServers.turn_password,
        },
        {
          urls: [this.turnServers.stun_address],
        }
      );
    }
    this.subscribePeer();
    this.handleFirstMessageTimeout(2);
    // Create the video player as the websocket is setting up.
    if (this.$refs.vjsVideo) {
      this.videojs = videojs(
        this.$refs.vjsVideo as HTMLVideoElement,
        this.videoOptions,
        () => {
          this.$emit("video-player-ready", this.videojs);
          // Add loading while we wait for webrtc to complete.
          this.videojs.addClass("vjs-waiting");
        }
      );
    } else {
      debugLogger("Couldn't find reference to video player!");
      this.videojs = videojs("vjsVideo");
    }

    window.addEventListener("beforeunload", () => {
      this.handleHangup(true);
    });
  }
  // --------- Methods ---------

  /** Handles the first message's timeout (if the timeout fires, we retry with a longer box timeout) */
  handleFirstMessageTimeout(retries = 2): void {
    // if we failed all attempts, fully fail
    if (retries === 0) {
      this.handleHangup(true, false);
      this.handlePeerFailure("error", "Failed to receive response from box.");
    } else {
      debugLogger(
        `Waiting for ${
          this.AWAITING_RESPONSE_TIMEOUT_MS / 1000
        } seconds before retrying.`
      );
      this.firstMessageReceivedTimeoutIds.push(
        setTimeout(() => {
          debugLogger(
            "Timeout waiting for websocket to respond. Hanging up and trying again."
          );
          this.boxTimeoutMs += 2000;
          // Clean up from last rendition
          this.handleHangup(true, false);

          // Create a new peer and video player
          this.subscribePeer();

          // Set another timeout in case we miss this one too.
          this.handleFirstMessageTimeout(retries - 1);
        }, this.AWAITING_RESPONSE_TIMEOUT_MS)
      );
    }
  }
  /** Once the webrtc_config is populated, save the information in it */
  saveWebrtcInformation(): void {
    this.TRIGGER_URL =
      this.webRTCConfig.websocket_api_url +
      "clients/" +
      encodeURIComponent(this.webRTCConfig.to_box_id) +
      "/trigger/";

    this.WEBSOCKET_URL = this.testingLocally
      ? "ws://localhost:2048"
      : this.webRTCConfig.websocket_wss_url +
        "?X-Device-ID=" +
        encodeURIComponent(this.webRTCConfig.to_browser_id);
    debugLogger(
      `The Trigger URL: ${this.TRIGGER_URL}`,
      `The Websocket URL: ${this.WEBSOCKET_URL}`,
      `The ID of to browser is ${this.webRTCConfig.to_browser_id}`,
      `The id of to box is ${this.webRTCConfig.to_box_id}.`
    );
  }
  /*********************************************************************
   *********************************************************************
   ******************* Socket Handler Methods **************************
   *********************************************************************
   *********************************************************************/

  /** Starts a timer that can be cancelled in the `tryCancelTimer` function */
  startTimer(
    retryAfterTimeout: (args?: any) => void,
    inputArgs?: InputArgs
  ): void {
    this.webrtcWebsocketTimeoutIds.push(
      setTimeout(() => retryAfterTimeout(inputArgs), this.TIMEOUT_LENGTH_MS)
    );
  }

  /** If the timeout is successfully cancelled,
   * this function updates us to the next step in the wrtc process.
   */
  tryCancelTimer(
    expectedResponseId: WsResponses,
    actualResponseId: WsResponses
  ): boolean {
    // check if should be allowed to cancel
    if (expectedResponseId === actualResponseId) {
      debugLogger("Cancelling timeout for " + actualResponseId);
      this.webrtcWebsocketTimeoutIds.map((timeout) => clearTimeout(timeout));
      return true;
    } else {
      debugLogger(
        "Failed to cancel timeout. Recieved " +
          actualResponseId +
          " but expected " +
          expectedResponseId
      );
      return false;
    }
  }

  /** A websocket connection that is created when this component is. */
  createWebsocket(
    wrtcCall: WrtcCall,
    sessionData: RTCSessionDescriptionInit,
    listenOnly = false
  ): void {
    debugLogger("Creating a websocket!", this.webRTCConfig);
    let onOpenFunction;
    if (!listenOnly) {
      onOpenFunction = () =>
        this.sendMessage(wrtcCall, this.sendOfferViaWebsocket, true, wrtcCall);
    } else {
      onOpenFunction = () => {
        debugLogger("Socket Opened!!");
      };
    }
    debugLogger("The onopen function: ", onOpenFunction);
    debugLogger("Create websocket args:", wrtcCall, sessionData, listenOnly);

    this.websocket = new Sockette(this.WEBSOCKET_URL, {
      timeout: 5000,
      maxAttempts: 3,
      onopen: onOpenFunction,
      onmessage: (message) => this.receiveMessage(message.data),
      onreconnect: (e) => debugLogger("Reconnecting websocket...", e),
      onmaximum: () =>
        this.handlePeerFailure(
          "error",
          "Failed to establish the communication channel."
        ),
      onclose: (e) => debugLogger("Closed websocket!", e),
      onerror: (e) => debugLogger("Websocket error!", e),
    });

    // once everything is set up, we have 30 seconds to get all the communication done
    this.totalWebrtcProcessTimeoutIds.push(
      setTimeout(() => {
        this.handlePeerFailure(
          "timed-out",
          "Took too long to connect to box. This could be due to a network failure. Try stopping and restarting the stream."
        );
      }, this.TOTAL_WEBRTC_PROCESS_TIMEOUT_MS)
    );
  }

  /*********************************************************************
   *********************************************************************
   ******************* Message Sending Methods *************************
   *********************************************************************
   *********************************************************************/

  /** When a message is received, check the type to determine what to do. */
  receiveMessage(message: string): void {
    let incomingMessage: WebsocketCommunicationResponse;
    try {
      incomingMessage = JSON.parse(message);
    } catch (err) {
      debugLogger("Parse message error:", err);
      return;
    }
    debugLogger("Received message:", message);
    // if we things don't match up, don't go any further
    if (!this.tryCancelTimer(this.expectingResponse, incomingMessage.type)) {
      debugLogger(
        "We couldn't cancel this timer because it was the wrong type: ",
        incomingMessage
      );
      return;
    }

    switch (incomingMessage.type) {
      case "webrtc-call":
        debugLogger("Receive " + incomingMessage.type, incomingMessage);
        this.clearAllTimeouts(this.firstMessageReceivedTimeoutIds);
        this.updateNextExpectedStep("webrtc-hangup");
        debugLogger("Here is the answer", incomingMessage.data.answer);
        this.peer.signal(incomingMessage.data.answer);
        break;
      case "webrtc-hangup":
        debugLogger("Receive " + incomingMessage.type, incomingMessage);
        this.updateNextExpectedStep("webrtc-call");
        this.handleHangup(false);
        break;
    }
  }

  /** Updates the camera's capabilities. */
  updateCameraCapabilities(messageJSON: string): boolean {
    try {
      const capabilities: string[] = JSON.parse(messageJSON);
      const liveWidget = (window as any).liveWidget;
      const thisCamera = this.webrtcOptions.webrtc_streamer_config.camera;
      if (
        liveWidget &&
        liveWidget.cameraByName &&
        liveWidget.cameraByName[thisCamera.name]
      ) {
        const cameraToUpdate: Camera = liveWidget.cameraByName[thisCamera.name];
        cameraToUpdate.capabilities = capabilities;
        return true;
      } else {
        debugLogger("No camera by this name! Failed to update capabilities!");
        return false;
      }
    } catch (err) {
      debugLogger("Failed to determine camera capabilities!");
      return false;
    }
  }
  /** Sends a request to the server to get webrtc config and figure out what state this box is in */
  async sendRequestToJava(
    payload: WrtcCall,
    sessionData: RTCSessionDescriptionInit
  ): Promise<void> {
    const connector = (window as any).connector;
    if (connector && connector.startWebRTC) {
      // TODO: In the future where everything is componentized, this approach won't work because
      // there can't be any assumptions/global variables... everything has to be passed everwhere and fetched by
      // the components themselves. Obviously this is prohibitively difficult right now (and will take too long due to time constraints)
      // but that is the eventual goal.
      let webrtcConfig: JavaServerRequestResponseActions;
      const tryRequest = async (
        retries = 1
      ): Promise<JavaServerRequestResponseActions> => {
        try {
          const config = await connector.startWebRTC(
            this.webrtcOptions.webrtc_streamer_config.camera,
            payload,
            this.isAutoLive
          );

          if (config === false) {
            return ActionsFromServer.USE_HLS;
          } else {
            // need to do this so that the camera knows it's webrtc
            this.updateCameraCapabilities(
              JSON.stringify(this.AUTO_START_LIVE_CAPABILITIES)
            );
            return config;
          }
        } catch (err: any) {
          /**
           * Error object looks something like this if it's a 409 (user agent mismatch)
              {
                "readyState": 4,
                "responseText": "{\"message\": [
                    \"name_changeability\",
                    \"video\",
                    \"per_camera_settings\",
                    \"new_authorizations\",
                    \"encoding_settings\",
                    \"rtsp_stream_reading\",
                    \"rtmp\"
                ]}",
                "responseJSON": {
                    "message": "[
                      \"name_changeability\",
                      \"video\",
                      \"per_camera_settings\",
                      \"new_authorizations\",
                      \"encoding_settings\",
                      \"rtsp_stream_reading\",
                      \"rtmp\"
                    ]"
                },
                "status": 409,
                "statusText": "error"
              }
           */

          debugLogger("Request to the server to start webrtc failed.", err);
          debugLogger(err);

          // In this case, we have either experienced a 409 (trying to do hls while in auto live)
          // or some other error code
          if (isJqueryError(err)) {
            // In the case of 409, we know this is HLS so we want to do hls if possible
            if (err.status === 409) {
              const successfulUpdate = this.updateCameraCapabilities(
                err.responseJSON.message
              );
              debugLogger(
                "This is a disallowed hls or webrtc request according to the server."
              );
              if (successfulUpdate) {
                debugLogger(
                  "Successfully updated the camera's capabilities thanks to the server. Returning use hls"
                );
                return ActionsFromServer.USE_HLS;
              } else {
                // TODO: Should this be a shutdown or an hls... what does shutdown do?
                debugLogger(
                  "Failed to update the camera's capabilities... shutting down and hopefully that's all good."
                );
                return ActionsFromServer.SHUT_DOWN;
              }
            } else if (err.status === 404) {
              debugLogger(
                "Got a 404 from the server. Likely box isn't online or doesn't exist."
              );
              return ActionsFromServer.NO_BOX_AVAILABLE;
            } else {
              // In some other error code (500, 4XX)
              if (retries > 0) {
                debugLogger(`Retrying request ${retries} more times!`);
                return tryRequest(retries - 1);
              } else {
                return ActionsFromServer.SHUT_DOWN;
              }
            }
          }
          return ActionsFromServer.SHUT_DOWN;
        }
      };

      // Potentially we would need to retry since server will error the first time if user agents don't match,
      // but won't error the second time.
      debugLogger("Trying to request from server with 409 check.");
      webrtcConfig = await tryRequest();
      debugLogger("Here is the webrtc config from server:", webrtcConfig);
      // If there is a config, check to see if we have sent the call to the box
      switch (webrtcConfig) {
        case ActionsFromServer.USE_HLS:
          debugLogger("Using HLS if not autolive.");
          // Start hls bc no webrtc was available
          if (
            this.isAutoLive &&
            this.webrtcOptions.webrtc_streamer_config.fromAutoStart
          ) {
            this.handlePeerFailure("error", "Can't autostart HLS/Webrtc.");
            this.promiseCallbackFunctions.resolve(true);
            this.handleHangup(true, false);
            this.updateCameraCapabilities(
              JSON.stringify(this.HLS_CAPABILITIES)
            );
          } else {
            this.promiseCallbackFunctions.resolve(false);
            this.handleHangup(true, true);
          }
          break;
        case ActionsFromServer.NO_BOX_AVAILABLE:
          debugLogger("No box available. User agent was null.");
          this.handlePeerFailure(
            "error",
            "Unable to find the box associated with this camera. Please check that the box is online and has not been deleted."
          );
          this.handleHangup(true, false);
          this.updateCameraCapabilities(JSON.stringify(this.HLS_CAPABILITIES));
          break;
        case ActionsFromServer.SHUT_DOWN:
          debugLogger("Shutting down.");
          // Resolve as true so we don't start hls
          this.promiseCallbackFunctions.resolve(true);
          // but then throw an error so we know we don't plan on starting
          this.handlePeerFailure("error", "Unable to start live stream");
          this.handleHangup(true, false);
          this.updateCameraCapabilities(JSON.stringify(this.HLS_CAPABILITIES));
          break;
        default:
          debugLogger("Starting instant-live.");
          // In this case, we have the config
          this.webRTCConfig = webrtcConfig;
          this.saveWebrtcInformation();
          if (this.webRTCConfig.call_sent) {
            debugLogger("The call was sent to the box!");
            this.createWebsocket(payload, sessionData, true);
          } else {
            this.createWebsocket(payload, sessionData);
          }
          this.promiseCallbackFunctions.resolve(true);
          break;
      }
    } else {
      // TODO: Just like earlier comment, this will go away once we componentize
      console.warn(
        "No connector available on this page... Can't do instant-live."
      );
    }
  }
  /** Handle any time we want to send a communication via the websocket. */
  sendMessage(
    payload: WebsocketCommunication,
    callbackOnFailure: (args?: any) => void,
    retry = true,
    inputArgs?: InputArgs
  ): void {
    if (!this.webRTCConfig) {
      // We can't send a message if we never got a webrtc config.
      return;
    }
    debugLogger("Send " + payload.type + " -> ", payload);
    if (this.testingLocally) {
      this.websocket.json(payload);
    } else {
      axios
        .post(this.TRIGGER_URL, JSON.stringify(payload), {
          headers: {
            "X-Camio-Websocket-Signature": this.webRTCConfig.signed_to_box_id,
            "Content-Type": "application/json",
          },
        })
        .then((result) => debugLogger(`Trigger Response of ${result.status}`))
        .catch((err) => console.error("Trigger Error:", err));
    }

    if (retry) {
      // Start a countdown timer to retry if there is a timeout
      this.startTimer(callbackOnFailure, inputArgs);
    }
  }

  /** Updates to the next expected step to keep track of timeouts. */
  updateNextExpectedStep(nextStep: WsResponses): void {
    this.expectingResponse = nextStep;
  }

  /*********************************************************************
   *********************************************************************
   ******************* Peer Connection Methods *************************
   *********************************************************************
   *********************************************************************/

  /** Sets up the peer connection and listeners. */
  subscribePeer(): void {
    this.peer = new SimplePeer({
      trickle: false,
      initiator: true,
      config: {
        iceServers: this.iceServers,
      },
    });

    this.peer.addTransceiver("video", {
      direction: "sendrecv",
    });

    this.peer.on("signal", (data) => {
      switch (data.type) {
        case "offer":
          debugLogger("Offer sdp", data.sdp);
          this.sendOffer(data as RTCSessionDescriptionInit);
      }
    });

    this.peer.on("track", (_, stream) => {
      debugLogger("Setting Stream to the following: ", stream);
      const vid = this.videojs.tech("Silence Warning").el() as HTMLVideoElement;
      vid.srcObject = stream;
    });

    this.peer.on("connect", () => {
      debugLogger("Connected");
      this.clearAllTimeouts(this.totalWebrtcProcessTimeoutIds);
    });

    this.peer.on("error", (err) => {
      debugLogger("Peer connection error:", err);
      this.handlePeerFailure("error", "Failed to connect to box.");
    });
  }

  /** Handles the logic for sending the initial offer. */
  async sendOffer(data: RTCSessionDescriptionInit): Promise<void> {
    const offer: WrtcCall = {
      type: "webrtc-call",
      local_camera_id:
        this.webrtcOptions.webrtc_streamer_config.local_camera_id, // Note: this is safe because of the check in the parent for webrtcOptions and it's children fields.
      socket_config: this.webRTCConfig, // At this point, webrtcConfig is undefined
      delay_before_websocket_push_msec: this.boxTimeoutMs,
      data: {
        offer: data,
      },
    };
    debugLogger("Send offer to java!");
    // Try to send offer via request
    this.sendRequestToJava(offer, data);
  }

  /** Handles the logic for sending offers via the websocket. */
  async sendOfferViaWebsocket(call: WrtcCall): Promise<void> {
    // need to rebuild the call here so that we populate the socket config
    const offer: WrtcCall = {
      type: "webrtc-call",
      local_camera_id:
        this.webrtcOptions.webrtc_streamer_config.local_camera_id, // Note: this is safe because of the check in the parent for webrtcOptions and it's children fields.
      socket_config: this.webRTCConfig,
      data: {
        offer: call.data.offer,
      },
    };
    debugLogger("Sending offer via websocket!", offer);
    // Try to send offer via request
    this.sendMessage(offer, this.sendOfferViaWebsocket, true, offer);
  }

  /** Used to terminate Webrtc connection and the communication channel (websocket) once the box knows we should close. */
  handleHangup(destroyImmediately: boolean, destroyPlayer = true): void {
    // if we didn't send that we were going to hang up, then we need to before closing
    if (!this.hasSentHangup) {
      const hangup: WrtcHangup = {
        type: "webrtc-hangup",
        socket_config: this.webRTCConfig,
        local_camera_id:
          this.webrtcOptions.webrtc_streamer_config.local_camera_id, // Note: this is safe because of the check in the parent for webrtcOptions and it's children fields.
        data: {},
      };

      this.hasSentHangup = true;
      this.sendMessage(hangup, this.handleHangup, false);
    }
    this.clearAllTimeouts(this.totalWebrtcProcessTimeoutIds);
    this.clearAllTimeouts(this.firstMessageReceivedTimeoutIds);
    this.clearAllTimeouts(this.webrtcWebsocketTimeoutIds);

    if (this.hasSentHangup || destroyImmediately) {
      if (this.peer && !this.peer.destroyed) {
        this.peer.destroy();
      }

      if (this.videojs && destroyPlayer) {
        this.videojs.dispose();
      }

      if (this.websocket) {
        this.websocket.close();
      }

      this.hasSentHangup = false;
    }
  }

  /** A function to handle peer failure due to a timeout or an error */
  handlePeerFailure(type: "error" | "timed-out", error?: string): void {
    const errorMessage = error
      ? error
      : "No error was provided, but something went wrong.";

    this.clearAllTimeouts(this.totalWebrtcProcessTimeoutIds);
    this.clearAllTimeouts(this.firstMessageReceivedTimeoutIds);

    if (this.videojs.el()) {
      this.videojs.removeClass("vjs-waiting");
    }

    this.$emit("video-error", error);

    switch (type) {
      case "error":
        debugLogger(errorMessage);
        // Don't do anything since we are advising the user to stop and start again.
        // Doing it on our own is too complicated right now.
        break;
      case "timed-out":
        // if we timed out, just give up.
        this.webrtcWebsocketTimeoutIds.forEach((id) => {
          clearTimeout(id);
        });
        break;
    }
    if (this.videojs.el()) {
      this.videojs.createModal(errorMessage, {
        description: errorMessage,
        fillAlways: true,
        temporary: false,
        label: errorMessage,
        uncloseable: true,
      });
    }
  }

  /** Used to clear all the timeouts in a list of timeouts */
  clearAllTimeouts(timeoutList: ReturnType<typeof setTimeout>[]): void {
    timeoutList.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }
}
