

import { Component, Vue } from "vue-property-decorator";
import TheWebRTCVideoSetup from "./components/TheWebRTCVideoSetup.vue";
import ThePanZoomOverlay from "./components/ThePanZoomOverlay.vue";
import {
  PromiseCallbackFunctions,
  TurnServers,
  WebRTCConfig,
  WebrtcOptions,
} from "./types";
import { debugLogger } from "./debug";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { webrtcOptions } from "@/main";

@Component({
  components: { TheWebRTCVideoSetup, ThePanZoomOverlay },
})
export default class WebrtcStreamer extends Vue {
  // ---------- Props ----------
  // ------- Local Vars --------

  /** Helps us destroy children before destroying root. */
  isDestroying = false;

  /** The webrtc config as returned from the java server */
  webRTCConfig: WebRTCConfig | null = null;

  /** If testing locally */
  TESTING_LOCALLY = process.env.NODE_ENV === "development";

  /** The video options that should be passed down to the videojs player. */
  videoOptions: VideoJsPlayerOptions = {
    autoplay: true,
    controls: true,
    controlBar: {
      playToggle: false,
      liveDisplay: true,
    },
    muted: true,
    bigPlayButton: false,
    fill: true,
    userActions: {
      doubleClick: false,
    },
  };

  /** The player that is handling the webrtc media stream. */
  videoPlayer: HTMLElement | null = null;

  /** Keeps track of whether the user is hovering over the video (to show zoom tools) */
  isHovering = false;

  /** Allows us to enable/disable panzoom. */
  panzoomDisabled = false;

  /** The options passed into this component on init. */
  webrtcOptions: WebrtcOptions = {} as WebrtcOptions;

  /** The global promise callbacks so that the function that embeds this component knows when everything is ready. */
  promiseCallbackFunctions: PromiseCallbackFunctions =
    {} as PromiseCallbackFunctions;

  /** The turn servers fetched from the api. */
  turnServers: TurnServers | boolean = false;

  /** Tells us if we are in the autolive state. If we are, that means we can't start hls. Default to true so we don't accidentally start hls. */
  isAutoLive = true;

  // --------- Watchers --------
  // ------- Lifecycle ---------
  async mounted(): Promise<void> {
    const noTypeWindow = window as any;
    const webrtcOptionsHasIncorrectChildren =
      webrtcOptions &&
      (!webrtcOptions.webrtc_streamer_config ||
        !webrtcOptions.webrtc_streamer_elements_to_query);

    if (!webrtcOptions) {
      debugLogger("No Webrtc Options passed into component!");
      this.$destroy();
      return;
    } else if (webrtcOptionsHasIncorrectChildren) {
      debugLogger(
        "Missing either webrtc_streamer_config or webrtc_streamer_elements_to_query and those are required!"
      );
      this.$destroy();
      return;
    } else {
      this.webrtcOptions = webrtcOptions;
      debugLogger("The webrtcOptions", webrtcOptions);
    }

    if (noTypeWindow.GLOBAL_SETTINGS) {
      if (
        noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_embed_resolve_functions
      ) {
        this.promiseCallbackFunctions =
          noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_embed_resolve_functions[
            this.webrtcOptions.webrtc_streamer_elements_to_query.componentId
          ];
        debugLogger("Callback functions found:", this.promiseCallbackFunctions);
      }
    }
    if (noTypeWindow.liveWidget && noTypeWindow.liveWidget.turnServers) {
      const turnServersRequestSucceeded = await noTypeWindow.liveWidget
        .turnServers;
      debugLogger(
        "Got the turn server request successfully?",
        turnServersRequestSucceeded
      );
      if (turnServersRequestSucceeded) {
        this.turnServers = { ...turnServersRequestSucceeded };
      } else {
        this.turnServers = {} as TurnServers;
      }
    } else {
      debugLogger("Unable to find turn servers!");
    }

    // first try to get the auto-start live state from the config (tells us if embedded by autostart or user manual click)
    if (
      webrtcOptions &&
      webrtcOptions.webrtc_streamer_config &&
      typeof webrtcOptions.webrtc_streamer_config.fromAutoStart === "boolean"
    ) {
      this.isAutoLive = webrtcOptions.webrtc_streamer_config.fromAutoStart;
    } else if (
      noTypeWindow.liveWidget &&
      typeof noTypeWindow.liveWidget.isAutoLive === "boolean"
    ) {
      this.isAutoLive = noTypeWindow.liveWidget.isAutoLive;
    }
  }

  beforeDestroy(): void {
    this.isDestroying = true;
  }
  // --------- Methods ---------

  /** When mounted is done, this should be true because these should all be defined */
  get startVideoSetup(): boolean {
    return (
      !this.isDestroying &&
      !!this.webrtcOptions &&
      !!this.promiseCallbackFunctions &&
      !!this.turnServers
    );
  }
  /** Sets the video player so panzoom can be activated. */
  setVideoPlayer(newPlayer: VideoJsPlayer): void {
    this.videoPlayer = newPlayer.el().children[0] as HTMLElement;
  }

  /** Function that handles the update of whether the user is hovering over the panzoom overlay. */
  hoverChange(isHovering: boolean): void {
    this.isHovering = isHovering;
  }

  /** Sets when the panzoom should be visible. */
  setPanzoomDisabled(isDisabled: boolean): void {
    this.panzoomDisabled = isDisabled;
  }
}
