/* eslint-disable camelcase */
export type PromiseCallbackFunctions = {
  resolve: (value: boolean) => void;
  reject: (value: any) => void;
};

export enum ActionsFromServer {
  USE_HLS,
  SHUT_DOWN,
  NO_BOX_AVAILABLE,
}

export type JavaServerRequestResponseActions =
  | ActionsFromServer.USE_HLS
  | ActionsFromServer.SHUT_DOWN
  | ActionsFromServer.NO_BOX_AVAILABLE
  | WebRTCConfig;

export type JQueryErrorResponse = {
  readyState: number;
  responseText: string;
  responseJSON: {
    message: string;
  };
  status: number;
  statusText: string;
};

export const isJqueryError = (
  err: JQueryErrorResponse | any
): err is JQueryErrorResponse => {
  return err?.responseText && err?.responseJSON && err?.status;
};

export type Camera = {
  actual_values: Record<string, unknown>;
  address_components: unknown[];
  camera_group_ids: string[];
  camera_id: string;
  capabilities: string[];
  device_id_internal: string;
  is_connected: boolean;
  is_online: boolean;
  labels: string[];
  local_camera_id: string;
  location: { lat: number; lng: number };
  mac_address: string;
  name: string;
  recent_uploads: boolean;
  resolution: any;
  rtsp_url_params: Record<string, unknown>;
  rtsp_url_pattern: string;
  tags: string[];
  user_agent: string;
  user_id: string;
  zones: string[];
};

export type WebrtcOptions = {
  webrtc_streamer_config: {
    local_camera_id: string;
    camera_name: string;
    camera: Camera;
    fromAutoStart: boolean; // Whether or not autolive started this stream
  };
  webrtc_streamer_elements_to_query: {
    parentId: string;
    componentId: string;
  };
};
export type WebRTCConfig = {
  signed_to_box_id: string;
  signed_to_browser_id: string;
  to_box_id: string;
  to_browser_id: string;
  websocket_api_url: string;
  websocket_wss_url: string;
  call_sent?: boolean;
} & TurnServers;

export type TurnServers = {
  stun_address: string;
  turn_address: string;
  turn_username: string;
  turn_password: string;
};

export type WrtcCall = {
  type: "webrtc-call";
  local_camera_id: string;
  socket_config: WebRTCConfig;
  delay_before_websocket_push_msec?: number;
  data: {
    offer: RTCSessionDescriptionInit;
  };
};

export type WrtcWrapper = {
  type: "webrtc";
  payload: WrtcCall;
};

export type WrtcCallResponse = {
  type: "webrtc-call";
  local_camera_id: string;
  socket_config: WebRTCConfig;
  delay_before_websocket_push_msec?: number;
  data: {
    answer: RTCSessionDescriptionInit;
  };
};

export type WrtcHangup = {
  type: "webrtc-hangup";
  local_camera_id: string;
  socket_config: WebRTCConfig;
  data: Record<string, never>;
};

export type WrtcHangupResponse = {
  type: "webrtc-hangup";
  local_camera_id: string;
  socket_config: WebRTCConfig;
  data: {
    success: boolean;
  };
};

export type RTCCandidate = {
  candidate: string;
  sdpMLineIndex: number;
  sdpMid: string;
};

export type RTCSessionDescriptionInit = {
  sdp?: string;
  type: RTCSdpType;
};

export type RTCSdpType = "answer" | "offer" | "pranswer" | "rollback";

export type RTCIceServer = {
  credential?: string;
  credentialType?: RTCIceCredentialType;
  urls: string | string[];
  username?: string;
};

export type WebsocketCommunication = WrtcCall | WrtcHangup;

export type WebsocketCommunicationResponse =
  | WrtcCallResponse
  | WrtcHangupResponse;

export type WsResponses = WrtcCallResponse["type"] | WrtcHangupResponse["type"];

export type InputArgs =
  | RTCSessionDescriptionInit
  | WebsocketCommunication
  | RTCIceCandidate[]
  | boolean
  | undefined;
