import Vue from "vue";
import WebRTCStreamer from "./WebrtcStreamer.vue";
import process from "process";
import { ButtonPlugin, IconsPlugin } from "bootstrap-vue";
import { debugLogger } from "./debug";
import { WebrtcOptions } from "./types";

Vue.use(IconsPlugin);
Vue.use(ButtonPlugin);
const noTypeWindow = window as any;

export let webrtcOptions: null | WebrtcOptions = null;
export let thisIndex = 0;
noTypeWindow.process = process;

noTypeWindow.embedWebrtcStreamer = (
  parentId: string, // Note this is also in the class on the object. In the form {cameraname}-stream
  componentEmbedId: string // This is an index of a unique stream, used for a unique id for the component {#}-webrtc-streamer
): void => {
  // if changing change here, in index.html, and in the app.vue file root element id
  const EMBED_PARENT = parentId || "rtmp";
  const COMPONENT_EMBED_ID = componentEmbedId || "webrtc-streamer";

  const embedVueInstance = (id: string, embedClassIdentifier: string): void => {
    let index = 0;
    debugLogger("The id passed into embed vue", id);
    const existsAlready = !!(
      noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_vue as []
    ).find((elem: { id: string; vue: Vue }, idx) => {
      index = idx;
      return elem.id === id;
    });

    debugLogger("Just about to mount", parentId, componentEmbedId);

    const element = document.querySelector(`#${componentEmbedId}`) as Element;
    if (element) {
      const webrtcData = element.attributes.getNamedItem("data-webrtc");
      if (webrtcData) {
        try {
          const potentiallyString: string | WebrtcOptions = JSON.parse(
            decodeURIComponent(webrtcData.value)
          );

          // Need to parse twice because since we are urlencoding to protect against html errors,
          // we end up accidentally double encoding.
          if (typeof potentiallyString === "string") {
            webrtcOptions = JSON.parse(potentiallyString);
          } else {
            webrtcOptions = potentiallyString;
          }
        } catch (err) {
          debugLogger("Somethig went wrong with the handoff of data.", err);
        }
      } else {
        debugLogger("No data attribute?");
      }
    } else {
      debugLogger("Element is null?");
    }
    const vueInstance = new Vue({
      render: (h: any) => h(WebRTCStreamer),
    }).$mount("#" + COMPONENT_EMBED_ID);

    if (existsAlready) {
      noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_vue[index].vue.$destroy();
      noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_vue[index].vue = vueInstance;
      thisIndex = index;
    } else {
      thisIndex = noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_vue.length;
      noTypeWindow.GLOBAL_SETTINGS.webrtc_streamer_vue.push({
        id,
        selector: embedClassIdentifier,
        vue: vueInstance,
        logs: "",
      });
    }

    debugLogger("Embedding new webrtc streamer with id " + id);
  };

  embedVueInstance(COMPONENT_EMBED_ID, EMBED_PARENT);
};
