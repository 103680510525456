

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import panzoom, { PanZoom, PanZoomOptions, TransformOrigin } from "panzoom";

@Component({
  components: {},
})
export default class ThePanZoomOverlay extends Vue {
  // ---------- Props ----------

  /** The video player element to do panzoom on */
  @Prop() videoPlayer!: HTMLElement;

  /** Show the panzoom tools when hovering over viewer. */
  @Prop() hovering!: boolean;

  // ------- Local Vars --------
  /** The panzoom object. */
  thePanZoomObject!: PanZoom;

  /** The current zoomLevel as a float. */
  zoomLevel!: number;

  /** Defined minimum and maximum zoom levels. */
  // MAX_ZOOM = 4;
  MIN_ZOOM = 1;

  /** The config for the panZoom */
  panzoomConfig: PanZoomOptions = {
    bounds: true,
    boundsPadding: 1,
    minZoom: this.MIN_ZOOM,
    // maxZoom: this.MAX_ZOOM,
    onDoubleClick: function () {
      return true; // tells the library to not preventDefault, and not stop propagation
    },
  };

  /** Whether or not to show the +/- magnifying glass buttons */
  showZoomTools = false;

  /** This tells panzoom where the "middle" of the panzoom is */
  tranformOrigin!: TransformOrigin;

  // --------- Watchers --------

  @Watch("hovering")
  userHovering(status: boolean): void {
    if (status && this.thePanZoomObject) {
      this.thePanZoomObject.resume();
      this.showZoomTools = true;
    } else if (this.thePanZoomObject) {
      this.thePanZoomObject.pause();
      this.showZoomTools = false;
    } else {
      console.warn("The PanZoom object is undefined!");
    }
  }

  @Watch("videoPlayer")
  videoPlayerChange(): void {
    // only set up the video with panzoom once the player exists
    if (this.videoPlayer) {
      this.setupVideo(this.videoPlayer);
    }
  }

  // ------- Lifecycle ---------

  // --------- Methods ---------

  /** Set up the video component with the panZoom config */
  setupVideo(videoElem: HTMLElement): void {
    this.zoomLevel = 1;
    this.tranformOrigin = {
      x: 0.5,
      y: 0.5,
    };
    this.thePanZoomObject = panzoom(videoElem, this.panzoomConfig);
    // Fix to make sure it stays in bounds but still allows zoom thanks to https://github.com/anvaka/panzoom/issues/33#issuecomment-498225590
    this.thePanZoomObject.on("panend", (e: PanZoom) => {
      const { x } = e.getTransform();
      const maxTranslate =
        videoElem.getBoundingClientRect().width - videoElem.clientWidth;

      if (Math.abs(x) >= maxTranslate) {
        e.moveBy(-(x + maxTranslate), 0, true);
      }
    });
  }

  /** Function to determine whether the user is hovering over the video. */
  userOver(status: boolean): void {
    if (status && this.thePanZoomObject) {
      this.thePanZoomObject.resume();
      this.showZoomTools = true;
    } else {
      this.thePanZoomObject.pause();
      this.showZoomTools = false;
    }
  }

  /** Handle zoom by a specified parameter. */
  zoom(value: number): void {
    this.zoomLevel = this.thePanZoomObject.getTransform().scale + value;
    this.thePanZoomObject.smoothZoomAbs(
      this.tranformOrigin.x,
      this.tranformOrigin.y,
      this.zoomLevel
    );
    // This is for if you want to set a Maximum zoom level
    // if (this.zoomLevel >= this.MAX_ZOOM) {
    //   this.thePanZoomObject.smoothZoomAbs(
    //     this.tranformOrigin.x,
    //     this.tranformOrigin.y,
    //     this.MAX_ZOOM
    //   );
    //   this.zoomLevel = this.MAX_ZOOM;
    // } else if (this.zoomLevel <= this.MIN_ZOOM) {
    //   this.thePanZoomObject.smoothZoomAbs(
    //     this.tranformOrigin.x,
    //     this.tranformOrigin.y,
    //     this.MIN_ZOOM
    //   );
    //   this.zoomLevel = this.MIN_ZOOM;
    // } else {
    //   this.thePanZoomObject.smoothZoomAbs(
    //     this.tranformOrigin.x,
    //     this.tranformOrigin.y,
    //     this.zoomLevel
    //   );
    // }
  }
}
